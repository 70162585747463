import * as yup from "yup";
import {
  address,
  email,
  firstName,
  kit_type,
  lastName,
  phoneNumber,
  pieces,
} from "../../constants/constants";
import { FormComponent } from "../../interfaces/FormComponent";
import { FormStructure } from "../../interfaces/FormStructure";

export const schema = yup.object().shape({
  field_email: email,
  field_first_name: firstName,
  field_last_name: lastName,
  field_order_telephone_number: phoneNumber,
  field_address: address,
  field_node_kit_type: kit_type,
  field_pieces: pieces,
});

export const getOrderKitFormStructure = (
  kitTypes: Array<any>,
  values,
  t
): FormStructure => {
  return {
    components: [
      {
        component: FormComponent.TextField,
        label: t("orderKitFormStructure.fields.email"),
        name: "field_email",
        type: "text",
        autoComplete: "email",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("orderKitFormStructure.fields.firstName"),
        name: "field_first_name",
        type: "text",
        autoComplete: "given-name",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("orderKitFormStructure.fields.lastName"),
        name: "field_last_name",
        type: "text",
        autoComplete: "family-name",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.AddressAutoComplete,
        label: t("orderKitFormStructure.fields.address"),
        name: "field_address",
        type: "text",
        autoComplete: "street-address",
      },
      {
        component: FormComponent.Select,
        label: t("orderKitFormStructure.fields.kitType"),
        name: "field_node_kit_type",
        type: "text",
        options: kitTypes?.map((kitType) => ({
          id: kitType.nid,
          label: kitType.title,
        })),
        id: "",
      },
      {
        component: FormComponent.TextField,
        label: t("orderKitFormStructure.fields.phoneNumber"),
        name: "field_order_telephone_number",
        type: "text",
        autoComplete: "tel",
        placeholder: " ",
        pattern: "[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]{7,}",
      },
      {
        component: FormComponent.Select,
        label: t("orderKitFormStructure.fields.unit"),
        name: "field_pieces",
        type: "text",
        options:
          kitTypes
            ?.find((kitType) => kitType.nid === values[0])
            ?.pieces?.map((piece) => ({ id: piece, label: piece })) || [],
        id: "",
      },
    ],
    schema: schema,
    title: t("orderKitFormStructure.title"),
    submitLabel: t("orderKitFormStructure.submit"),
  };
};
