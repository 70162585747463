import { Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../api/Api";
import "../../assets/scss/OrderKit/OrderKit.scss";
import Dialog from "../../component/Dialog";
import Footer from "../../component/Footer/Footer";
import DynamicForm from "../../component/Form/DynamicForm";
import { ATTEMPT_ORDER_KIT } from "../../constants/constants";
import useDisclosure from "../../hooks/useDisclosure";
import ContentType from "../../interfaces/ContentType";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import { PageType } from "../../interfaces/Pages";
import { getContentType } from "../../store/PageContentActions";
import { contentState, loadingState } from "../../store/Reducer";
import { finishedLoading, loading } from "../../store/ToolBoxActions";
import { createGAEvent, getLoadingData, wrapFormData } from "../../util/util";
import { getOrderKitFormStructure, schema } from "./OrderKitFormStructure";

export default function OrderKitForm() {
  const content = useSelector(contentState)[PageType.orderKit];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading, isSuccess } = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_ORDER_KIT
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const kitTypes = content?.kit_type || [];

  useEffect(() => {
    dispatch(getContentType(PageType.orderKit, ContentType.KIT_TYPE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data, setError) => {
    const targetIdFields = ["field_node_kit_type"];
    data = wrapFormData(data, targetIdFields);
    data.type = "order_a_kit";
    delete data.field_order_address_postal_code;

    const kit = kitTypes.find((kit) => kit.nid.toString() === data["field_node_kit_type"].target_id);

    if (!kit || !kit.pieces.includes(data["field_pieces"][0])) {
      setError("field_pieces", { type: "custom", message: "yup.orderKit.unit" });
    } else {
      dispatch(loading(ATTEMPT_ORDER_KIT));

      Api.postForm(data)
        .then((response) => {
          createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.KIT_ORDERED);
          onOpen();
          dispatch(finishedLoading(ATTEMPT_ORDER_KIT, response?.data, true));
        })
        .catch((error) => {
          dispatch(finishedLoading(ATTEMPT_ORDER_KIT, error?.message, false));
        });
    }
  };

  return (
    <>
      <Container component="main" className={"contact-us-page order-kit-page"}>
        <Box className="contact-us-form ">
          <Box className={"contact-us-form-header"}>
            <Typography component="h1" variant="h1" className="main-title">
              {t("orderKitFormStructure.title")}
            </Typography>
          </Box>
          <Box className="contact-us-form-items">
            <DynamicForm
              getFormStructure={(values) =>
                getOrderKitFormStructure(kitTypes, values, t)
              }
              schema={schema}
              watchFields={["field_node_kit_type"]}
              onSubmit={onSubmit}
              classes={"contact-us-form-items-inner"}
              loading={isLoading}
              success={isSuccess}
            />
            <Dialog
              open={isOpen}
              button1Text={t("orderKitFormStructure.dialog.close")}
              onButton1={() => {
                onClose();
              }}
              contentText={t("orderKitFormStructure.dialog.message")}
              title={t("orderKitFormStructure.dialog.title")}
              className="form"
            />
          </Box>
        </Box>
      </Container>
      <Footer legal_number={"MP-BIO/IDA 2401-ABR23"} />
    </>
  );
}
