import { Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { default as React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Api } from "../../api/Api";
import "../../assets/scss/CoolBagForm/Form.scss";
import Footer from "../../component/Footer/Footer";
import DynamicForm from "../../component/Form/DynamicForm";
import { ATTEMPT_REGISTER_PATIENT } from "../../constants/constants";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import { PageType, RegisterPatientPage } from "../../interfaces/Pages";
import { getContent } from "../../store/PageContentActions";
import { contentState, loadingState, toolBoxState } from "../../store/Reducer";
import { finishedLoading, loading } from "../../store/ToolBoxActions";
import mapDataToEntityFormat, { createGAEvent, getLoadingData } from "../../util/util";
import { getRegisterPatientFormStructure } from "./RegisterFormStructure";

export default function RegisterFormPatient(props) {
  const conditions = useSelector(toolBoxState).conditions;
  const content = useSelector(contentState);
  const dispatch = useDispatch();
  const history = useHistory();
  const registerPatientPage = content[
    PageType.registerPatient
  ] as RegisterPatientPage;
  const formStructure = getRegisterPatientFormStructure(
    registerPatientPage,
    conditions
  );

  const { isLoading, isSuccess } = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_REGISTER_PATIENT
  );

  useEffect(() => {
    dispatch(getContent(PageType.registerPatient));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data) => {
    data.name = data.email;

    const formData = mapDataToEntityFormat(data);

    dispatch(loading(ATTEMPT_REGISTER_PATIENT));

    Api.registerPatient(formData).then(
      (result) => {
        createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.PATIENT_REGISTERED);
        dispatch(
          finishedLoading(ATTEMPT_REGISTER_PATIENT, result.message, true)
        );
        history.push("/patient/thank-you");
      },
      (error) => {
        dispatch(
          finishedLoading(ATTEMPT_REGISTER_PATIENT, error.message, false)
        );
      }
    );
  };

  const { t } = useTranslation();

  return (
    <>
      <Container
        component="main"
        className={"register-page patient-register-page"}
      >
        <Box className="register-form register-patient-form">
          <div className="register-form-inner-container">
            <Box>
              <Typography variant="h1" className="main-title">
                {t("register.form.patient.pageTitle")}
              </Typography>
            </Box>
            <div className="register-form-items">
              <DynamicForm
                formStructure={formStructure}
                onSubmit={handleSubmit}
                loading={isLoading}
                success={isSuccess}
              />
            </div>
          </div>
        </Box>
      </Container>
      <Footer legal_number={content?.["register-patient"]?.legal_number} />
    </>
  );
}
