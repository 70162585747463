import { Container, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { ReactNode } from "react";

export interface DialogProps {
  title?: string;
  button1Text?: string;
  button2Text?: string;
  contentText?: string;
  renderContent?: ReactNode | null;
  onButton1?: () => void;
  onButton2?: () => void;
  open: boolean;
  className?: string;
  error?: string;
}

const useStyles = makeStyles((theme) => ({
  error: {
    color: "#E37E08",
  },
}));

export default function Dialog({
  title,
  button1Text,
  button2Text,
  contentText,
  renderContent,
  onButton1,
  onButton2,
  open,
  error,
  ...props
}: DialogProps) {
  const classes = useStyles();

  return (
    <Container component="div" maxWidth="md">
      <MuiDialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        {...props}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {error && (
          <DialogContent>
            <DialogContentText id="alert-dialog-error">
              <div dangerouslySetInnerHTML={{ __html: error }} className={classes.error}></div>
            </DialogContentText>
          </DialogContent>
        )}
        <DialogContent>
          {contentText && (
            <DialogContentText id="alert-dialog-description">
              <div dangerouslySetInnerHTML={{ __html: contentText }}></div>
            </DialogContentText>
          )}
          {renderContent}
        </DialogContent>
        <DialogActions>
          {button1Text && (
            <Button
              onClick={onButton1}
              color="primary"
              aria-label={button1Text}
            >
              {button1Text}
            </Button>
          )}
          {button2Text && (
            <Button
              onClick={onButton2}
              color="primary"
              autoFocus
              aria-label={button2Text}
            >
              {button2Text}
            </Button>
          )}
        </DialogActions>
      </MuiDialog>
    </Container>
  );
}
