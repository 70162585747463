import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Api } from "../../api/Api";
import Dialog from "../../component/Dialog";
import Footer from "../../component/Footer/Footer";
import DynamicForm from "../../component/Form/DynamicForm";
import PageContainer from "../../component/PageContainer/PageContainer";
import ResponseHandler from "../../component/ResponseHandler";
import { ATTEMPT_CHANGE_PASSWORD } from "../../constants/constants";
import useDisclosure from "../../hooks/useDisclosure";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import { PageType } from "../../interfaces/Pages";
import { loadingState } from "../../store/Reducer";
import { finishedLoading, loading } from "../../store/ToolBoxActions";
import mapDataToEntityFormat, { createGAEvent, getLoadingData } from "../../util/util";
import { getChangePasswordFormStructure } from "./ChangePasswordFormStructure";

function ChangePassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading, isSuccess } = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_CHANGE_PASSWORD
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const handleSubmit = (submitData) => {
    dispatch(loading(ATTEMPT_CHANGE_PASSWORD));
    submitData = { ...submitData, field_token: query.get("token") };

    Api.changePassword(mapDataToEntityFormat(submitData))
      .then((resp) => {
        createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.PASSWORD_CHANGED);
        onOpen();
        dispatch(finishedLoading(ATTEMPT_CHANGE_PASSWORD, resp.message, true));
      })
      .catch((error) => {
        dispatch(
          finishedLoading(ATTEMPT_CHANGE_PASSWORD, error.message, false)
        );
      });
  };

  return (
    <>
      <PageContainer dataType={PageType.changePassword}>
        <DynamicForm
          formStructure={getChangePasswordFormStructure(t)}
          onSubmit={handleSubmit}
          loading={isLoading}
          success={isSuccess}
        />
        <Dialog
          open={isOpen}
          button1Text={t("changePasswordFormStructure.dialog.close")}
          onButton1={() => {
            onClose();
            history.push("/login");
          }}
          contentText={t("changePasswordFormStructure.dialog.message")}
          title={t("changePasswordFormStructure.dialog.title")}
          className="form"
        />
        <ResponseHandler />
      </PageContainer>
      <Footer legal_number={"Change/Password/Test"} />
    </>
  );
}

export default ChangePassword;
