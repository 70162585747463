import * as yup from "yup";
import { FormComponent } from "../../../interfaces/FormComponent";
import { FormStructure, Option } from "../../../interfaces/FormStructure";
import MedicationType from "../../../interfaces/MedicationType";
import { MedicationEditPageType } from "../../../interfaces/Pages";

// eslint-disable-next-line react-hooks/rules-of-hooks
import i18next from "i18next";

export const frequencies: Option[] = [
  {id: "1", label:"medicationPages.frequency.1time"},
  {id: "2", label: "medicationPages.frequency.2times"},
  {id: "7", label: "medicationPages.frequency.weekly"},
  {id: "15", label: "medicationPages.frequency.biweekly"},
  {id: "30", label: "medicationPages.frequency.monthly"},
  {id: "60", label: "medicationPages.frequency.bimonthly"},
  {id: "90", label: "medicationPages.frequency.quarterly"},
];

export const getEditMedicationFormStructure = (
  medication: MedicationType
): FormStructure => {
  return {
    components: [
      {
        component: FormComponent.TextField,
        label: i18next.t("medicationFormStructure.name.label"),
        name: "field_medication_label",
        type: "text",
        defaultValue: medication?.name,
        placeholder: " ",
      },
      {
        component: FormComponent.SelectDropdown,
        label: i18next.t("medicationFormStructure.frequency.label"),
        type: "text",
        name: "field_medication_frequency",
        defaultValue: medication?.frequency,
        options: frequencies.map(
            (n) => ({ id: n.id, label: i18next.t(n.label) })
        )
      },
      {
        component: FormComponent.DatePicker,
        label: i18next.t("medicationFormStructure.date.label"),
        name: "field_medication_starting_date",
        disablePast: true,
        type: "text",
        defaultValue: medication?.startingDate,
      },
      {
        component: FormComponent.TimePicker,
        label: i18next.t("medicationFormStructure.time.label"),
        name: "field_medication_starting_date",
        type: "text",
        defaultValue: medication?.startingDate,
      },
    ],
    schema: yup.object().shape({
      field_medication_label: yup
        .string()
        .required(i18next.t("error.required.medication")),
      field_medication_frequency: yup
        .string()
        .required("Se requiere frecuencia"),
      field_medication_starting_date: yup
        .date()
        .required(i18next.t("error.required.date"))
        .min(new Date(new Date().setHours(0, 0, 0, 0)), "Hora incorrecta. La fecha tiene que ser posterior a la medianoche de hoy."),
    }),
  };
};

export const getCreateMedicationFormStructure = (
  content: MedicationEditPageType
) => {
  return {
    components: [
      {
        component: FormComponent.TextField,
        label: i18next.t("medicationFormStructure.name.label"),
        name: "field_medication_label",
        type: "text",
        placeholder: " ",
      },
      {
        component: FormComponent.SelectDropdown,
        label: i18next.t("medicationFormStructure.frequency.label"),
        type: "number",
        name: "field_medication_frequency",
        options: frequencies.map(
            (n) => {
              console.log({ id: n.id, label: i18next.t(n.label) });
              return { id: n.id, label: i18next.t(n.label) };
            }
        )
      },
      {
        component: FormComponent.DatePicker,
        label: i18next.t("medicationFormStructure.date.label"),
        name: "field_medication_starting_date",
        disablePast: true,
        type: "text",
        noAnimation: true,
        defaultValue: new Date(),
      },
      {
        component: FormComponent.TimePicker,
        label: i18next.t("medicationFormStructure.time.label"),
        name: "field_medication_starting_date",
        type: "text",
        noAnimation: true,
        defaultValue: new Date(),
      },
    ],
    schema: yup.object().shape({
      field_medication_label: yup
        .string()
        .required(i18next.t("error.required.medication")),
      field_medication_frequency: yup
        .string()
        .required("Se requiere frecuencia"),
      field_medication_starting_date: yup
        .date()
        .required(i18next.t("error.required.date")),
    }),
  };
};
