import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import "../../../assets/scss/components/SymptomGallery/SymptomGalleryItem/SymptomGalleryItem.scss";
import { MagnifyableImage } from "../../MagnifyAbleImage";

const SymptomGalleryItem = ({
  image,
  url = "#",
  title,
  alt,
  altExtraLong,
  zoomable,
  isAccordion,
}) => {
  const longTitle = title.length >= 70 ? "long-title" : "";

  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleArrowClick = () => {
    if (!isOpen) {
      setIsOpen(!isOpen);
    } else {
      setIsClosing(true);

      const myTimeout = setTimeout(() => {
        setIsOpen(!isOpen);
        setIsClosing(false);
        clearTimeout(myTimeout);
      }, 1000);
    }
  };

  const hasLongAlt = altExtraLong.length > 0;

  const imageContainer =
    url.length === 0 ? (
      <div className={"no-link-image"}>
        <img
          src={image}
          alt={title}
          className="item-image"
          height={120}
          width={120}
        />
        <div
          className={`item-title ${isOpen ? "open" : ""} ${
            isClosing ? "closing" : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          onClick={() => handleArrowClick()}
        />
        {alt.length > 0 && alt !== "-" && (
          <div
            className="item-alt"
            dangerouslySetInnerHTML={{
              __html: hasLongAlt ? altExtraLong : alt,
            }}
          />
        )}
      </div>
    ) : (
      <RouterLink to={url}>
        <img
          src={image}
          alt={title}
          className="item-image"
          height={120}
          width={120}
        />
        <div
          className="item-title"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </RouterLink>
    );

  return (
    <div className={`symptom-gallery-item ${longTitle}`}>
      {zoomable ? (
        <>
          <MagnifyableImage
            src={image as string}
            alt={title as string}
            className="item-image"
            height={"120"}
            width={"120"}
          />
          <div
            className="item-title"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </>
      ) : (
        imageContainer
      )}
    </div>
  );
};

export default SymptomGalleryItem;
