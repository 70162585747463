import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../../assets/scss/DynamicPage/DynamicPage.scss";
import { Attachments } from "../../component/Attachments/Attachments";
import CTA from "../../component/CTA/CTA";
import CTADouble from "../../component/CTA/CTADouble";
import Footer from "../../component/Footer/Footer";
import Hero from "../../component/Hero/Hero";
import { ContentPageContainer } from "../../component/PageContainer/ContentPageContainer";
import ProsConsBox from "../../component/ProsConsBox/ProsConsBox";
import { Section } from "../../component/SectionArticle/Section";
import SectionAccordion from "../../component/SectionArticle/SectionAccordion";
import SectionArticle from "../../component/SectionArticle/SectionArticle";
import { SectionCarousel } from "../../component/SectionArticle/SectionCarousel";
import { SectionColumns } from "../../component/SectionArticle/SectionColumns";
import { SectionIFrame } from "../../component/SectionArticle/SectionIFrame";
import SingleCard from "../../component/SingleCard/SingleCard";
import SingleCardGallery from "../../component/SingleCard/SingleCardGallery";
import SymptomGallery from "../../component/SymptomGallery/SymptomGallery";
import { DynamicPageType, SectionContent } from "../../interfaces/Pages";
import { SectionType } from "../../interfaces/SectionType";
import { contentState } from "../../store/Reducer";

export const DynamicPage = () => {
  const location = useLocation().pathname.replace("/", "").replace("'", "%27");
  const content: DynamicPageType = useSelector(contentState)[
    location.replaceAll("%27", "'")
  ];

  const renderSection = (section: SectionContent, index: number) => {
    switch (section.type) {
      case SectionType.simple:
        if (section.body2) {
          return (
            <section className={"simple-section-with-multiple-body"}>
              <Section
                key={index}
                title={section.title}
                subtitle={section.subtitle}
                desc={section.body}
                link={section.link}
                linkLabel={section.subtitle2}
                image={section.image}
              />
              {section.body2 && (
                <Section
                  key={index}
                  title={section.title2}
                  subtitle=""
                  desc={section.body2}
                  link={section.link2}
                  linkLabel=""
                  image={section.image}
                />
              )}
            </section>
          );
        }

        return (
          <Section
            key={index}
            title={section.title}
            subtitle={section.subtitle}
            desc={section.body}
            link={section.link}
            linkLabel={section.subtitle2}
            image={section.image}
          />
        );

      case SectionType.split:
        return (
          <>
            <SectionArticle
              key={index}
              string_1={section.subtitle}
              string_2={section.title}
              desc={section.body}
              link={section.link2}
              linkLabel={section.title3}
            />
            {section.body2 && (
              <SectionArticle
                key={index}
                string_1={section.subtitle2}
                string_2={section.title2}
                desc={section.body2}
                link=""
                linkLabel=""
              />
            )}
            {section.body3 && (
              <SectionArticle
                key={index}
                string_1=""
                string_2=""
                desc={section.body3}
                link=""
                linkLabel=""
              />
            )}
          </>
        );

      case SectionType.reverse_split:
        return (
          <SectionArticle
            key={index}
            string_1={section.subtitle}
            string_2={section.title}
            desc={section.body}
            isReverse
            link={section.link}
            linkLabel={section.subtitle2}
          />
        );

      case SectionType.white_card:
        return (
          <SingleCard
            key={index}
            title={section.title}
            description={section.body}
            description2={section.body2}
            image={section.image}
            imageAlignment={section.image_alignment}
            imageHeight={section.image_height}
            className={"white-card"}
          />
        );

      case SectionType.white_card_gallery:
        return (
          <SingleCardGallery
            key={index}
            title={section.title}
            description={section.body}
            carousel={section.carousel_items}
            hasBody
            className={"single-card-gallery-outer-container"}
          />
        );

      case SectionType.text_left_symptom_carousel_right:
        return (
          <div className={"text-left-symptom-carousel-right"} key={index}>
            <div className={"text-left-symptom-carousel-right-inner"}>
              <div className="left-side-container">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle}
                  image={section.image}
                />
                <Section
                  key={index}
                  title={section.title2}
                  subtitle=""
                  desc={section.body2}
                  link={section.link2}
                  linkLabel={section.subtitle2}
                  image={section.image}
                />
              </div>
              <section key={index}>
                <SymptomGallery gallery={section.gallery_items} />
              </section>
            </div>
          </div>
        );

      case SectionType.text_right_symptom_carousel_left:
        return (
          <div className={"text-left-symptom-carousel-right"} key={index}>
            <div
              className={
                "text-left-symptom-carousel-right-inner flex-row-reverse"
              }
            >
              <div className="left-side-container">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle}
                  image={section.image}
                />
                <Section
                  key={index}
                  title={section.title2}
                  subtitle=""
                  desc={section.body2}
                  link={section.link2}
                  linkLabel={section.subtitle2}
                  image={section.image}
                />
              </div>
              <section key={index}>
                <SymptomGallery gallery={section.gallery_items} />
              </section>
            </div>
          </div>
        );

      case SectionType.text_left_symptom_carousel_accordion_right:
        return (
          <div
            className={"text-left-symptom-carousel-right accordion-carousel"}
            key={index}
          >
            <div className={"text-left-symptom-carousel-right-inner"}>
              <div className="left-side-container">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle}
                  image={section.image}
                />
                <Section
                  key={index}
                  title={section.title2}
                  subtitle=""
                  desc={section.body2}
                  link={section.link2}
                  linkLabel={section.subtitle2}
                  image={section.image}
                />
              </div>
              <section key={index}>
                <SymptomGallery
                  gallery={section.gallery_items}
                  isAccordion={true}
                />
              </section>
            </div>
          </div>
        );

      case SectionType.text_right_symptom_carousel_accordion_left:
        return (
          <div
            className={"text-left-symptom-carousel-right accordion-carousel"}
            key={index}
          >
            <div
              className={
                "text-left-symptom-carousel-right-inner flex-row-reverse"
              }
            >
              <div className="left-side-container">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle}
                  image={section.image}
                />
                <Section
                  key={index}
                  title={section.title2}
                  subtitle=""
                  desc={section.body2}
                  link={section.link2}
                  linkLabel={section.subtitle2}
                  image={section.image}
                />
              </div>
              <section key={index}>
                <SymptomGallery gallery={section.gallery_items} />
              </section>
            </div>
          </div>
        );

      case SectionType.text_top_symptom_carousel_bottom:
        return (
          <div className={"text-top-symptom-carousel-bottom"} key={index}>
            <div className={"text-top-symptom-carousel-bottom-inner"}>
              <div
                className={`top-side-container ${
                  section.body2 ? "two-column-body" : "one-column-body"
                }`}
              >
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle}
                  image={section.image}
                />
                <Section
                  key={index}
                  title={section.title2}
                  subtitle=""
                  desc={section.body2}
                  link={section.link2}
                  linkLabel={section.subtitle2}
                  image={section.image}
                />
              </div>
              <section key={index}>
                <SymptomGallery gallery={section?.gallery_items} />
              </section>
            </div>
          </div>
        );

      case SectionType.content_with_mobile_image_right:
        return (
          <div className="content-with-image mobile-image image-on-right" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.content_with_tablet_image_left:
        return (
          <div className="content-with-image tablet-image image-on-left" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle="placeholder"
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.content_with_tablet_image_right:
        return (
          <div className="content-with-image tablet-image image-on-right" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.content_with_notebook_image_right:
        return (
          <div className="content-with-image notebook-image image-on-right" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.content_with_notebook_image_left:
        return (
          <div className="content-with-image notebook-image image-on-left" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle=""
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.blue_full_content_with_cta_below:
      case SectionType.cta:
        return (
          <div className="full-width-content-with-cta" key={index}>
            <div className="full-width-content-with-cta-inner">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc={section.body}
                link=""
                linkLabel=""
                image={section.image}
              />
              <div className="call-to-action-container">
                <CTA
                  key={index}
                  button_label={section.subtitle}
                  button_url={section.link}
                  description=""
                  title=""
                />
                {section.link2 && (
                  <CTA
                    key={index}
                    button_label={section.subtitle2}
                    button_url={section.link2}
                    description=""
                    title=""
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.below_hero:
        return (
          <div className="below-hero" key={index}>
            <div className="below-hero-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc={section.body}
                link={section.link}
                linkLabel={section.subtitle2}
                image={section.image}
              />
            </div>
          </div>
        );

      case SectionType.split_content_with_tablet_image_left:
        return (
          <div className="content-with-image tablet-image image-on-left split-title" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.split_content_with_tablet_image_right:
        return (
          <div className="content-with-image tablet-image image-on-right split-title" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.split_content_with_mobile_image_left:
        return (
          <div className="content-with-image mobile-image image-on-left split-title" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.split_content_with_mobile_image_right:
        return (
          <div className="content-with-image mobile-image image-on-right split-title" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.split_content_with_notebook_image_left:
        return (
          <div className="content-with-image notebook-image image-on-left split-title" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.split_content_with_notebook_image_right:
        return (
          <div className="content-with-image notebook-image image-on-right split-title" key={index}>
            <div className="content-with-image-inner-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc=""
                link=""
                linkLabel=""
                image={section.image}
                className={"image-container"}
              />
              <div className="text-column">
                <Section
                  key={index}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                  className={"text-container"}
                />
                {section.body2 && (
                  <Section
                    key={index}
                    title={section.title2}
                    subtitle=""
                    desc={section.body2}
                    link={section.link2}
                    linkLabel={section.subtitle2}
                    image={section.image}
                    className={"text-container"}
                  />
                )}
              </div>
            </div>
          </div>
        );

      case SectionType.white_card_split:
        return (
          <section key={index} className="white-card-split-section-container">
            <SingleCard
              title={section.title}
              description={section.body}
              className={"single-card-split-container"}
            />
            <Section
              title={section.title2}
              linkLabel={section.subtitle2}
              desc={section.body2}
              link={section.link}
            />
          </section>
        );

      /*case SectionType.cta:
        return (
          <CTA
            key={index}
            button_label={section.subtitle}
            button_url={section.link}
            description={section.body}
            title={section.title}
          />
        );*/

      case SectionType.cta_double:
        return (
          <CTADouble
            key={index}
            button_label1={section.subtitle}
            button_url1={section.link}
            body1={section.body}
            title1={section.title}
            button_label2={section.subtitle2}
            button_url2={section.link2}
            body2={section.body2}
            title2={section.title2}
          />
        );

      case SectionType.gallery_items:
        return (
          <section key={index} className={"gallery-items-outer-container"}>
            <div className="gallery-items-inner-container">
              <SectionArticle
                string_2={section.title}
                string_1={section.subtitle}
                desc={section.body}
              />
              <SymptomGallery gallery={section.gallery_items} />
            </div>
          </section>
        );

      case SectionType.carousel_items:
        return (
          <SectionCarousel
            key={index}
            carousel={section.carousel_items}
            desc={section.body}
            title={section.title}
            hasDesc={content.sections.length === 1}
            hasText={content.sections.length !== 1}
            className={`carousel-container section-${index}`}
          />
        );

      case SectionType.column_items:
        return (
          <SectionColumns key={index} columnItems={section.column_items} />
        );

      case SectionType.iframe:
        return (
          <SectionIFrame
            key={index}
            title={section.title}
            subtitle={section.subtitle}
            desc={section.body}
            url={section.link}
          />
        );

      case SectionType.content_left_multi_image_right:
        return (
          <section className="content-with-multiple-images image-on-right" key={index}>
            <Section
              key={index}
              title={section.title}
              subtitle={section.subtitle}
              desc={section.body}
              link={section.link}
              linkLabel={section.subtitle2}
            />
            {section.body2 && (
              <Section
                key={index}
                title={section.title2}
                subtitle={section.subtitle2}
                desc={section.body2}
                link={section.link2}
                linkLabel={section.subtitle2}
              />
            )}
            <div className="multi-image-container">
              {section.images &&
                section.images.map((img) => {
                  return (
                    <Section
                      key={img.target_id}
                      title={section.title}
                      subtitle={section.subtitle}
                      desc={section.body}
                      link={section.link}
                      linkLabel={section.subtitle2}
                      image={img}
                    />
                  );
                })}
              {section.image && (
                <Section
                  key={section.image.target_id}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc={section.body}
                  link={section.link}
                  linkLabel={section.subtitle2}
                  image={section.image}
                />
              )}
            </div>
          </section>
        );

      case SectionType.content_with_simple_image_right:
        return (
          <section className="content-with-simple-image image-on-right" key={index}>
            <Section
              key={index}
              title="placeholder"
              subtitle=""
              desc=""
              link=""
              linkLabel=""
              image={section.image}
              className={"simple-image-container"}
            />
            <div className="content-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc={section.body}
                link={section.link}
                linkLabel={section.subtitle}
              />
              <Section
                key={index}
                title={section.title2}
                subtitle=""
                desc={section.body2}
                link={section.link2}
                linkLabel={section.subtitle2}
              />
              {section.body3 && (
                <Section
                  key={index}
                  title={section.title3}
                  subtitle=""
                  desc={section.body3}
                  link=""
                  linkLabel=""
                />
              )}
            </div>
          </section>
        );

      case SectionType.content_with_simple_image_left:
        return (
          <section className="content-with-simple-image image-on-left" key={index}>
            <Section
              key={index}
              title="placeholder"
              subtitle=""
              desc=""
              link=""
              linkLabel=""
              image={section.image}
              className={"simple-image-container"}
            />
            <div className="content-container">
              <Section
                key={index}
                title={section.title}
                subtitle=""
                desc={section.body}
                link={section.link}
                linkLabel={section.subtitle}
              />
              <Section
                key={index}
                title={section.title2}
                subtitle=""
                desc={section.body2}
                link={section.link2}
                linkLabel={section.subtitle2}
              />
              {section.body3 && (
                <Section
                  key={index}
                  title={section.title3}
                  subtitle=""
                  desc={section.body3}
                  link=""
                  linkLabel=""
                />
              )}
            </div>
          </section>
        );

      case SectionType.accordion:
        return (
          <SectionAccordion
            key={index}
            title={section.title}
            body={section.body}
          />
        );

      case SectionType.triple:
        return (
          <section
            className={"simple-section-with-multiple-title-and-body no-image"}
            key={index}
          >
            <Section
              key={index}
              title={section.title}
              subtitle={section.subtitle}
              desc={section.body}
              link={section.link}
              linkLabel={section.subtitle2}
              image={section.image}
            />
            <Section
              key={index}
              title={section.title2}
              subtitle=""
              desc={section.body2}
              link={section.link2}
              linkLabel=""
              image={section.image}
            />
          </section>
        );

      case SectionType.content_top_multi_boxes_bottom:
        return (
          <section className={"content-top-multi-boxes-bottom-outer"} key={index}>
            <div className="content-top-multi-boxes-bottom-inner">
              <Section
                key={index}
                title={section.title}
                subtitle={section.subtitle}
                desc={section.body}
                link={section.link}
                linkLabel={section.subtitle2}
                image={section.image}
              />
              <div className="box-wrapper">
                <Section
                  key={index}
                  title={section.title2}
                  subtitle=""
                  desc={section.body2}
                  link={section.link2}
                  linkLabel=""
                  image={section.image}
                />
                <Section
                  key={index}
                  title={section.title3}
                  subtitle=""
                  desc={section.body3}
                  link={section.link2}
                  linkLabel=""
                  image={section.image}
                />
              </div>
            </div>
          </section>
        );

      case SectionType.content_with_pros_and_cons_boxes:
        return (
          <section className={"content-with-pros-and-cons-boxes-outer"} key={index}>
            <div className="content-with-pros-and-cons-boxes-inner">
              <div className="content-wrapper">
                <Section
                  key={index}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc={section.body}
                  link={section.link}
                  linkLabel=""
                  image={section.image}
                />
                <Section
                  key={index}
                  title={section.title2}
                  subtitle={section.subtitle2}
                  desc={section.body}
                  link={section.link}
                  linkLabel=""
                  image={section.image}
                />
              </div>
              <div className="box-wrapper">
                <ProsConsBox
                  title={section.link2}
                  body={section.body2}
                  icon={true}
                />
                {section.title3 && (
                  <ProsConsBox
                    title={section.title3}
                    body={section.body3}
                    icon={false}
                  />
                )}
              </div>
            </div>
          </section>
        );

      case SectionType.content_with_pros_box:
        return (
          <section
            className={"content-with-pros-and-cons-boxes-outer no-cons-box"}
            key={index}
          >
            <div className="content-with-pros-and-cons-boxes-inner">
              <div className="row-wrapper">
                <Section
                  key={index}
                  title={section.title}
                  subtitle={section.subtitle}
                  desc=""
                  link=""
                  linkLabel=""
                  image={section.image}
                />
                <ProsConsBox
                  title={section.link}
                  body={section.body}
                  icon={true}
                />
              </div>
              <div className="row-wrapper">
                <Section
                  key={index}
                  title={section.title2}
                  subtitle={section.subtitle2}
                  desc=""
                  link=""
                  linkLabel=""
                  image={section.image}
                />
                {section.link2 && (
                  <ProsConsBox
                    title={section.link2}
                    body={section.body2}
                    icon={true}
                  />
                )}
              </div>
            </div>
          </section>
        );
    }
  };

  return (
    <>
      <ContentPageContainer>
        <div
          className={`lower-section ${
            content?.main_page ? "main-page" : "content-page no-max-width"
          }`}
        >
          <Hero />
          {content &&
            content.sections &&
            content.sections.map((section, i) => renderSection(section, i))}

          {content?.attachments1 && (
            <Attachments
              title={content.attachments1_title}
              body={content.attachments1_body}
              attachments={content.attachments1}
              className={"attachment-section"}
            />
          )}

          {content?.attachments2 && (
            <Attachments
              title={content.attachments2_title}
              body={content.attachments2_body}
              attachments={content.attachments2}
              className={"attachment-section"}
            />
          )}
        </div>
      </ContentPageContainer>
      <Footer legal_number={content?.legal_number} />
    </>
  );
};
