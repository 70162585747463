import "../../assets/scss/Footer/Footer.scss";
import { LinkType } from "../../interfaces/LinkType";
import logo from "../../resources/logo/fresenius-kabi.png";
import { generateFooterLinks } from "../../util/util";

export interface DynamicFooterType {
  disclaimer_normal: string;
  disclaimer_situational: string;
  disclaimer_situational_vis: string;
  date: string;
  links: Array<LinkType>;
  legal_number: string;
}

export default function DynamicFooter({
  disclaimer_normal = "",
  disclaimer_situational = "",
  disclaimer_situational_vis = "",
  date = "",
  links = [],
  legal_number = "",
}: DynamicFooterType) {
  return (
    <div className={"footer"}>
      <div className="footer-inner-container">
        {disclaimer_situational_vis && (
          <div
            className={"disclaimer situational"}
            dangerouslySetInnerHTML={{
              __html: disclaimer_situational,
            }}
          />
        )}
        <div
          className={"disclaimer"}
          dangerouslySetInnerHTML={{
            __html: disclaimer_normal,
          }}
        ></div>
        <div className={"bottom"}>
          <div className={"logo"}>
            <img src={logo} alt="Logo" width="238" height="64" />
          </div>
          <div className={"content"}>
            <div className="preparation-container">
              <div className={"date"}>
                <p>{date}</p>
              </div>
              <div className="legal-number">{legal_number === "" ? "MP-BIO/IDA 2401-ABR23" : legal_number}</div>
            </div>
            <div className={"links"}>{generateFooterLinks(links)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
