import React from "react";
import * as yup from "yup";
import {
  access_code,
  condition,
  confirm_password,
  country,
  email,
  firstName,
  healthcare_organization,
  lastName,
  optIn,
  password,
  privacyPolicy,
  registration_number,
  title,
} from "../../constants/constants";
import { Condition } from "../../interfaces/Condition";
import { FormComponent } from "../../interfaces/FormComponent";
import { FormStructure } from "../../interfaces/FormStructure";
import { RegisterHCPPage, RegisterPatientPage } from "../../interfaces/Pages";
import { changeLink } from "../../util/util";
import { useTranslation } from "react-i18next";

export const getRegisterHCPFormStructure = (
  hcpPage: RegisterHCPPage,
  t
): FormStructure => {
  return {
    components: [
      {
        component: FormComponent.Select,
        label: t("register.form.hcp.title"),
        name: "title",
        type: "text",
        options: Object.values(
          [
            {'value': 'dr', 'label': 'Dr'},
            {'value': 'prof', 'label': 'Profe'},
            {'value': 'mr', 'label': 'Sr'},
            {'value': 'ms', 'label': 'Sra'},
          ]
        ).map((title) => ({ id: title.value, label: title.label })),
        autoComplete: "honorific-prefix",
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.email"),
        name: "email",
        type: "text",
        autoComplete: "email",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.firstName"),
        name: "firstName",
        type: "text",
        autoComplete: "given-name",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.password"),
        name: "password",
        type: "password",
        autoComplete: "new-password",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.lastName"),
        name: "lastName",
        type: "text",
        autoComplete: "family-name",
        placeholder: " ",
        required: true,
      },

      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.confirmPassword"),
        name: "confirm_password",
        type: "password",
        autoComplete: "new-password",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.registrationNumber"),
        name: "registration_number",
        type: "text",
        autoComplete: "off",
        placeholder: " ",
        pattern: "^[a-zA-Z0-9-_.]{1,}$",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.hcp.healthcareOrganization"),
        name: "healthcare_organization",
        type: "text",
        autoComplete: "organization",
        placeholder: " ",
        pattern: "^[a-zA-Z0-9-_.]{1,}$",
        required: true,
      },
      {
        component: FormComponent.CheckBox,
        label: (
          <div
            dangerouslySetInnerHTML={{
              __html: changeLink(t("register.form.hcp.readPolicy")),
            }}
          ></div>
        ),
        name: "field_user_email_sending",
        defaultChecked: false,
        type: "checkbox",
        autoComplete: "off",
      },
    ],
    schema: yup.object().shape({
      title: title,
      firstName: firstName,
      lastName: lastName,
      healthcare_organization: healthcare_organization,
      email: email,
      password: password,
      confirm_password: confirm_password,
      registration_number: registration_number,
      field_user_email_sending: privacyPolicy,
    }),
    //title: t('register.form.hcp.pageTitle'),
    submitLabel: t("register.form.hcp.submitLabel"),
  };
};

export const getRegisterPatientFormStructure = (
  registerPatientPage: RegisterPatientPage,
  conditions: Array<Condition>
): FormStructure => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return {
    components: [
      {
        component: FormComponent.TextField,
        label: t("register.form.patient.email"),
        name: "email",
        type: "email",
        autoComplete: "email",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.Select,
        label: t("register.form.patient.diagnosedCondition"),
        name: "field_diagnosed_condition_ref",
        type: "select",
        options: conditions,
        autoComplete: "off",
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.patient.accessCode"),
        name: "access_code",
        type: "text",
        autoComplete: "off",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.patient.password"),
        name: "password",
        type: "password",
        autoComplete: "new-password",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("register.form.patient.confirmPassword"),
        name: "confirm_password",
        type: "password",
        autoComplete: "new-password",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.CheckBox,
        label: (
          <div
            dangerouslySetInnerHTML={{
              __html: changeLink(t("register.form.patient.readPolicy")),
            }}
          ></div>
        ),
        name: "field_user_email_sending",
        type: "checkbox",
        autoComplete: "off",
      },
    ],
    schema: yup.object().shape({
      email: email,
      field_diagnosed_condition_ref: condition,
      access_code: access_code,
      password: password,
      confirm_password: confirm_password,
      field_user_email_sending: optIn,
    }),
    //title: registerPatientPage.register_patient_page_title,
    submitLabel: t("register.form.patient.submitLabel"),
  };
};
