import React from "react";
import { AuthLink } from "../../routing/AuthLink";
import { noop, sanitizeURL } from "../../util/util";
import { useTranslation } from "react-i18next";

const MenuItem = ({ url, roles, label }) => {
  const { t } = useTranslation();

  return (
    <div className="menu-item">
      <AuthLink to={sanitizeURL(url)} roles={roles} onClose={noop}>
        {url === "/home" ? t("nav.home") : label}
      </AuthLink>
    </div>
  );
};

export default MenuItem;
