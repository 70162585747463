import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../component/Footer/Footer";

export default function ThankYouPage() {
  const { t } = useTranslation();

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Box mt={25} mb={59}>
          <Box mt={9}>
            <Typography component="h3" variant="h3">
              {t("coolbag.thankYou.title")}
            </Typography>
          </Box>
          <Box mt={14} className="align">
            <div dangerouslySetInnerHTML={{ __html: t("coolbag.thankYou.message") }}></div>
          </Box>
        </Box>
      </Container>
      <Footer legal_number={""} />
    </>
  );
}
