import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentType from "../../interfaces/ContentType";
import { MyProgressPageType, PageType } from "../../interfaces/Pages";
import { getContentType } from "../../store/PageContentActions";
import { contentState } from "../../store/Reducer";
import ToolboxPdfCard from "./ToolboxPdfCard/ToolboxPdfCard";

const ToolboxPdf = () => {
  const dispatch = useDispatch();
  const pageContent: MyProgressPageType = useSelector(contentState)[
    PageType.myProgress
  ];

  useEffect(() => {
    dispatch(
      getContentType(PageType.myProgress, ContentType.TOOLBOX_PDF_CONTENT)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="toolbox-pdf-section">
      <div className="toolbox-pdf-inner-container">
        {pageContent?.toolbox_pdf_content?.map((pdf) => (
          <ToolboxPdfCard
            icon={pdf.image?.url}
            title={pdf.title}
            description={pdf.body}
            buttonLabel={"Descargar PDF"}
            buttonHref={pdf.attachment?.field_media_document?.url}
          />
        ))}
      </div>
    </div>
  );
};

export default ToolboxPdf;
